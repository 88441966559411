import { httpsCallable } from "firebase/functions";
import firebase, { functions } from "../firebase";
import { cloudFunctionNames, firebaseCollectionNames } from "../utils";
import { logException } from "../utils/exceptionLogger";
const { admins, users } = firebaseCollectionNames;

const getUser = async (userId: string) => {
  return firebase
    .firestore()
    .collection(users)
    .doc(userId)
    .get()
    .then(doc => {
      if (doc.exists) {
        return {
          id: doc.id,
          name: doc.data()?.name,
          country: doc.data()?.country,
          locale: doc.data()?.locale,
        };
      }
    });
};

const subscribe = (
  userId: BackofficeUser["id"],
  callback: (err: Error | null, user: BackofficeUser | null) => void
) =>
  firebase
    .firestore()
    .collection(admins)
    .doc(userId)
    .onSnapshot(
      snapshot => {
        if (!snapshot.exists) {
          return callback(null, null);
        }
        const data = snapshot.data();
        return callback(null, {
          id: snapshot.id,
          name: data?.name,
          partnerId: data?.partnerId || null,
          freshAccount: data.freshAccount || false,
          email: data.email || "",
          roles: data.roles || {},
          countries: data.countries || {},
          isDeleted: data.isDeleted || false,
        });
      },
      err => {
        console.log(err);
        logException(err);
        callback(err, null);
      }
    );

const exists = (userId: BackofficeUser["id"]) =>
  firebase
    .firestore()
    .collection(admins)
    .doc(userId)
    .get()
    .then(doc => doc.exists);

const createUser = (userId: BackofficeUser["id"], userName: string = null) =>
  firebase
    .firestore()
    .collection(admins)
    .doc(userId)
    .set({ id: userId, name: userName, partnerId: null }, { merge: true });

const getUserByUserIdOrEmail = async (searchText: string) => {
  try {
    const getUserCallable = httpsCallable(
      functions,
      cloudFunctionNames.getUser
    );
    const result = await getUserCallable({ searchText: searchText });
    return result.data as User;
  } catch (error) {
    console.log("error occured on get user", error);
    throw error;
  }
};

const updateName = (userId: BackofficeUser["id"], name: string) =>
  firebase
    .firestore()
    .collection("admins")
    .doc(userId)
    .set({ name }, { merge: true });

const deleteUser = async (userId: string, comment: string, reason: string) => {
  try {
    const deleteUserCallable = httpsCallable(
      functions,
      cloudFunctionNames.deleteUser
    );
    const result = await deleteUserCallable({
      uid: userId,
      comment: comment,
      reason: reason,
    });
    return result.data;
  } catch (error) {
    console.log("error occured while deleting record", error);
    throw error;
  }
};

export const UserService = {
  subscribe,
  exists,
  createUser,
  updateName,
  getUserByUserIdOrEmail,
  deleteUser,
  getUser,
};

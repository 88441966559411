import { Row, Col, Space, Typography, SelectProps, Button } from "antd";
import React, { FC } from "react";
import BJInput from "../atoms/BJInput";
import BJSelect from "../atoms/BJSelect";
import { IoIosSearch } from "react-icons/io";
import styled, { useTheme } from "styled-components";
import Layout from "antd/lib/layout/layout";
import { CSVLink } from "react-csv";
import { FaFileDownload, FaFileUpload } from "react-icons/fa";
import { MilestonesType, uploadCSV, UploadProps } from "../../../utils";
import { useLocation, useParams } from "react-router-dom";
import {
  useArticles,
  useDietaryAdvices,
  useMilestones,
  usePoll,
} from "../../../context";
import _ from "lodash";
import { UploadCSVModal } from "../../UploadCSVModal";

const UploadCSV = ({
  isUpload,
  topic,
}: {
  isUpload: boolean;
  topic: UploadProps["topic"];
}) => {
  const { id } = useParams<string>();
  const { pollService } = usePoll(id);
  const { createDietaryAdvice } = useDietaryAdvices();
  const location = useLocation();
  const isChildPage = location.pathname.includes(MilestonesType.Child);
  const { milestonesService } = useMilestones();
  const { articles } = useArticles();
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const hideModal = () => {
    setIsModalVisible(false);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const onUploadAccepted = (results: any) => {
    const value: string[][] = results.data;
    const fileredValue = value.filter((_, i) => i !== 0);
    //parse csv to data
    uploadCSV({
      topic: topic,
      val: fileredValue,
      articles,
      pollService,
      milestonesService,
      isChildPage,
      createDietaryAdvice,
    });
  };

  if (!isUpload) {
    return;
  }

  return (
    <>
      <UploadCSVModal
        isVisible={isModalVisible}
        modalActions={{ hide: hideModal, show: showModal }}
        onUploadAccepted={onUploadAccepted}
      />

      <Button
        onClick={showModal}
        type="ghost"
        size="small"
        style={{ borderWidth: 0 }}
      >
        <FaFileUpload size={24} />
      </Button>
    </>
  );
};

const Download2CSV = ({
  downloadData,
  title,
}: {
  downloadData: string[][];
  title: string;
}) => {
  if (!downloadData) {
    return;
  }
  return (
    <DownloadContainer>
      <CSVLink
        style={{ display: "flex" }}
        data={downloadData}
        filename={`${title?.toLocaleLowerCase()}-${Date.now()}.csv`}
      >
        <FaFileDownload size={24} />
      </CSVLink>
    </DownloadContainer>
  );
};

const BJTableHeader: FC<BJTableHeaderProps> = ({
  title,
  create,
  createOptions,
  createPlaceholder,
  inputPlaceholder,
  suffixButton,
  downloadData,
  prefixButton,
  onChangeInput,
  isUpload,
  hideSearch,
  value,
  topic,
}) => {
  const theme = useTheme();

  return (
    <Layout>
      <StyledSpace direction="vertical">
        <Row align="middle" gutter={[16, 16]}>
          {title && (
            <Col>
              <Row justify="start" align="middle">
                <StyledTitle level={3}>{title ?? "Title"}</StyledTitle>
              </Row>
            </Col>
          )}
          <Col>
            <Row justify="start">
              {create ? (
                <BJSelect
                  options={createOptions ?? []}
                  placeholder={createPlaceholder ?? "N/A"}
                  size="large"
                />
              ) : prefixButton ? (
                prefixButton
              ) : null}
            </Row>
          </Col>
          <Col flex={1}>
            <Row justify="end">
              {
                <>
                  {isUpload && <UploadCSV topic={topic} isUpload={isUpload} />}
                  {downloadData && (
                    <Download2CSV downloadData={downloadData} title={title} />
                  )}
                  {suffixButton && suffixButton}
                </>
              }
            </Row>
          </Col>
        </Row>
        {!hideSearch && (
          <Row>
            <BJInput
              value={value}
              BJDefault
              placeholder={inputPlaceholder ?? "Enter..."}
              size="small"
              onChange={onChangeInput}
              prefix={<IoIosSearch color={theme.icon.gray} size={"1.5rem"} />}
            />
          </Row>
        )}
      </StyledSpace>
    </Layout>
  );
};

type OptionsProps = SelectProps<any>["options"];
interface BJTableHeaderProps {
  title?: string;
  create: boolean;
  createPlaceholder?: string;
  createOptions?: OptionsProps;
  prefixButton?: JSX.Element;
  suffixButton?: JSX.Element;
  downloadData?: string[][];
  inputPlaceholder?: string;
  isUpload?: boolean;
  hideSearch?: boolean;
  onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  topic?: UploadProps["topic"];
}

const DownloadContainer = styled.div`
  display: flex;
  align-self: center;
  display: flex;
  margin: 0 5px 0 5px;
`;

const UploadContainer = styled.div`
  margin: 5px 5px 0 5px;
`;

const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    margin-bottom: 0rem !important;
  }
`;

const StyledSpace = styled(Space)`
  width: 100%;
`;

export default BJTableHeader;

import { DocumentData, QueryDocumentSnapshot } from "@firebase/firestore-types";
import { firestore } from "../firebase";
import { uploadImage } from "./serviceUtils";
import { formatTimeStampToMoment } from "../utils/commonUtils";
import { CountryCodeService } from "./CountryCodeService";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";

const miniJourneyCollectionRef = (countryCode: string) =>
  firestore.collection(CollectionPaths.MiniJourney[countryCode]);
const UserProgressSubcollection = "userProgress";
const miniJourneyFileStoragePath = "miniJourney";

export class MiniJourneyService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  toPoll = (doc: QueryDocumentSnapshot<DocumentData>): MiniJourneyPoll => {
    const data = doc.data();
    return {
      ...data,
      translations: data.translations,
      taskId: data.taskId,
      id: doc.id,
    };
  };

  toSubmission = (
    doc: QueryDocumentSnapshot<DocumentData>
  ): MiniJourneySubmission => {
    const data = doc.data();
    const tasks = data?.task || [];
    return {
      userId: doc.id,
      tasks: tasks.map((task: MiniJourneySubmittedTask) => {
        return {
          answerId: task.answerId,
          id: task.id,
          status: task.status,
        };
      }),
    };
  };

  toMiniJourney = async (
    doc: QueryDocumentSnapshot<DocumentData>
  ): Promise<MiniJourney> => {
    const data = doc.data();
    const pollsQuerySnapshot = await doc.ref.collection("polls").get();
    const pollsData = pollsQuerySnapshot.docs.map(this.toPoll);

    const tasks: MiniJourneyTaskFormData[] = data?.tasks?.map(
      (task: MiniJourneyTask) => {
        const poll = pollsData.find((poll: any) => poll.taskId === task.id);

        return {
          ...task,
          pollTranslations: poll?.translations,
          answers: poll?.answers,
          taskId: poll?.taskId,
          pollId: poll?.id,
        };
      }
    );

    return {
      ...data,
      id: doc.id,
      endTime: formatTimeStampToMoment(data.endTime),
      startTime: formatTimeStampToMoment(data.startTime),
      translations: data.translations,
      headerImage: data.headerImage,
      isActive: data.isActive,
      isLoyaltyProgressShown: data.isLoyaltyProgressShown,
      hideCountdown: data.hideCountdown,
      tasks: tasks,
      rewards: data.rewards,
      rewardImageBanner: data.rewardImageBanner,
    };
  };

  subscribe = (
    callback: (err: Error | null, miniJourneys: MiniJourney[]) => void
  ) =>
    miniJourneyCollectionRef(this.countryCode).onSnapshot(
      async snapshot => {
        const _miniJourneysPromises = snapshot.docs.map(this.toMiniJourney);
        const _miniJourneys = await Promise.all(_miniJourneysPromises);
        callback(null, _miniJourneys);
      },
      err => {
        logException(err);
        callback(err, []);
      }
    );

  create = async (miniJourney: MiniJourney, polls: MiniJourneyPoll[]) => {
    return miniJourneyCollectionRef(this.countryCode)
      .add(miniJourney)
      .then(docRef => {
        polls.forEach(item => {
          miniJourneyCollectionRef(this.countryCode)
            .doc(docRef.id)
            .collection("polls")
            .doc(item.taskId)
            .set(item);
        });
      });
  };

  update = (id: string, miniJourney: MiniJourney, polls: MiniJourneyPoll[]) => {
    return miniJourneyCollectionRef(this.countryCode)
      .doc(id)
      .update(miniJourney)
      .then(() => {
        polls.forEach(item => {
          miniJourneyCollectionRef(this.countryCode)
            .doc(id)
            .collection("polls")
            .doc(item.taskId)
            .set(item, { merge: true });
        });
      });
  };

  delete = (id: string) =>
    miniJourneyCollectionRef(this.countryCode).doc(id).delete();

  uploadMiniJourneyImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    const url = uploadImage(file, miniJourneyFileStoragePath, fileName);
    return url;
  };

  subscribeToSubmissions = (
    miniJourneyId: string,
    callback: (err: Error | null, data: MiniJourneySubmission[]) => void
  ) =>
    firestore
      .collection(CollectionPaths.MiniJourney[this.countryCode])
      .doc(miniJourneyId)
      .collection(UserProgressSubcollection)
      .onSnapshot(
        async snapshot => {
          const docs = snapshot.docs.map(this.toSubmission);
          callback(null, docs);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );
}

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Row,
  Col,
  Typography,
  Form,
  DatePicker,
  Select,
  Button,
  Divider,
} from "antd";
import {
  useMiniJourney,
  useMiniJourneys,
  useSponsors,
  useCountry,
} from "../../context";
import * as yup from "yup";
import moment from "moment";
import { AspectRatio, formValidationError, isEqualArrays } from "../../utils";
import { FormEdit, FormEditType } from "../../components";
import { DropAndCrop } from "../../components/DropAndCrop";
import { commonErrors } from "../../language";
import { yupResolver } from "@hookform/resolvers/yup";
import { BJInputFormItem, BJSwitchFormItem } from "../../components/theme";
import { TaskModal } from "./TaskModal";
import { ImagePresenter } from "../../components/ImagePresenter";
import { RewardModal } from "./RewardModal";
import styled from "styled-components";
import BJCopy from "../../components/theme/atoms/BJCopy";
import { BJMdFormItem } from "../../components/theme/molecules/formItems/BJFormMarkdown";

type FormValues = {
  translations: {
    [locale: string]: {
      headerHeadline: string;
      introText: string;
      sponsorText?: string;
      popupTitle?: string;
      popupDescription?: string;
    };
  };
  tasks: MiniJourneyTask[];
  rewards: MiniJourneyReward[];
  headerImage: string;
  headerBlurhash?: string;
  isActive: boolean;
  isLoyaltyProgressShown: boolean;
  hideCountdown: boolean;
  startEnd: [any, any];
  rewardImageBanner: string;
  rewardBlurhash?: string;
  sponsorId?: string;
};

const { RangePicker } = DatePicker;

export const MiniJourneyPage = () => {
  const navigate = useNavigate();
  const { sponsors } = useSponsors();
  const { id } = useParams<string>();
  const { currentCountry, primaryLocale } = useCountry();

  const schema = yup.object().shape({
    headerImage: yup
      .string()
      .required(`Header Image: ${commonErrors.requiredError}`)
      .nullable(),
    rewardImageBanner: yup
      .string()
      .required(`Reward Image Banner: ${commonErrors.requiredError}`)
      .nullable(),
    isActive: yup.boolean().nullable(),
    isLoyaltyProgressShown: yup.boolean().nullable(),
    sponsorId: yup.string().optional(),
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          headerHeadline: yup.string(),
          introText: yup
            .string()
            .required(
              `Intro Text (${String(item.key).toUpperCase()}): ${
                commonErrors.requiredError
              }`
            ),
          sponsorText: yup.string().optional(),
        });
        return acc;
      }, {} as any)
    ),
  });

  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
  } = useForm<FormValues>({ resolver: yupResolver(schema) });
  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false);
  const [isAddRewardModalVisible, setIsAddRewardModalVisible] = useState(false);
  const [storedSponsor, setStoredSponsor] = useState<Sponsor | null>(null);
  const [tasks, setTasks] = useState<MiniJourneyTaskFormData[]>([]);
  const [rewards, setRewards] = useState<MiniJourneyReward[]>([]);
  const [selectedTask, setSelectedTask] =
    useState<MiniJourneyTaskFormData | null>(null);

  const [selectedReward, setSelectedReward] =
    useState<MiniJourneyReward | null>(null);

  const { miniJourney, loading: loadingMiniJourney } = useMiniJourney(id);
  const {
    createMiniJourney,
    updateMiniJourney,
    deleteMiniJourney,
    uploadMiniJourneyImage,
  } = useMiniJourneys();

  const sponsor = watch("sponsorId");
  const headerHeadline = watch(
    `translations.${primaryLocale.key}.headerHeadline`
  );

  const editTask = (task: MiniJourneyTaskFormData) => {
    setSelectedTask(task);
    setIsAddTaskModalVisible(true);
  };

  const editReward = (reward: MiniJourneyReward) => {
    setSelectedReward(reward);
    setIsAddRewardModalVisible(true);
  };

  useEffect(() => {
    if (loadingMiniJourney || miniJourney === null) {
      return;
    }
    const startTime = miniJourney.startTime
      ? moment(miniJourney.startTime)
      : null;
    const endTime = miniJourney.endTime ? moment(miniJourney.endTime) : null;

    const { tasks, rewards, sponsor, ...rest } = miniJourney;
    reset({
      ...rest,
      startEnd: [startTime, endTime],
      sponsorId: sponsor?.id ?? "",
    });
    setStoredSponsor(sponsor ?? null);
    setTasks(tasks);
    setRewards(rewards);
  }, [miniJourney, reset, loadingMiniJourney]);

  const onSubmit = async (data: FormValues) => {
    const startTime = data.startEnd?.[0]?.seconds(0)?.toDate();
    const endTime = data.startEnd?.[1]?.seconds(0)?.toDate();
    const sponsor: Sponsor =
      sponsors.find(sponsor => sponsor.id === data.sponsorId) ?? null;
    const modifiedTasks: MiniJourneyTask[] = [];
    const tasksPoll: MiniJourneyPoll[] = [];
    const translations: MiniJourney["translations"] = {};

    for (const task of tasks) {
      const _task: MiniJourneyTask = {
        id: task.id,
        type: task.type,
        translations: task.translations,
        contentType: task.contentType,
        image: task.image,
        blurhash: task.blurhash,
        url: task.url,
        articleId: task.articleId,
        offerId: task.offerId,
        podcastId: task.podcastId,
        episodeId: task.episodeId,
      };

      const _poll: MiniJourneyPoll = {
        translations: task.pollTranslations,
        taskId: task.id,
        answers: task.answers,
      };
      modifiedTasks.push(_task);
      tasksPoll.push(_poll);
    }

    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        headerHeadline: value.headerHeadline,
        introText: value.introText,
        sponsorText: value.sponsorText ?? "",
        popupTitle: value.popupTitle ?? "",
        popupDescription: value.popupDescription ?? "",
      };
    }

    const updateData: MiniJourney = {
      translations: translations,
      headerImage: data.headerImage,
      headerBlurhash: data.headerBlurhash,
      isActive: data.isActive,
      isLoyaltyProgressShown: data.isLoyaltyProgressShown,
      rewardImageBanner: data.rewardImageBanner,
      rewardBlurhash: data.rewardBlurhash,
      sponsor,
      tasks: modifiedTasks,
      rewards: sponsor ? rewards : [],
      startTime,
      endTime,
      hideCountdown: data.hideCountdown,
    };
    if (miniJourney) {
      await updateMiniJourney(miniJourney.id, updateData, tasksPoll);

      return navigate(`../`);
    } else {
      await createMiniJourney(updateData, tasksPoll);
      return navigate(`../`);
    }
  };

  const onRemove = async () => {
    if (miniJourney) {
      await deleteMiniJourney(miniJourney.id);
      return navigate(`../`);
    }
  };
  const handleHeaderImageUrl = (url: string | null) => {
    setValue("headerImage", url, { shouldDirty: true });
  };

  const handleRewardImageBannerUrl = (url: string | null) => {
    setValue("rewardImageBanner", url, { shouldDirty: true });
  };

  const isRewardNoMJ =
    sponsor?.length >= 1
      ? rewards.length > 0 && !!Object.keys(dirtyFields).length
      : !!Object.keys(dirtyFields).length;

  const handleHeaderBlurhash = (url: string | null) => {
    setValue("headerBlurhash", url, { shouldDirty: true });
  };

  const handleRewardBlurhash = (url: string | null) => {
    setValue("rewardBlurhash", url, { shouldDirty: true });
  };

  const isDirtyNoMiniJourney =
    tasks.length >= 1 && tasks.length <= 6 && isRewardNoMJ;

  const isDirtyMiniJourney =
    tasks.length >= 1 &&
    tasks.length <= 6 &&
    (!!Object.keys(dirtyFields).length ||
      !isEqualArrays(miniJourney?.tasks ?? [], tasks) ||
      !isEqualArrays(miniJourney?.rewards ?? [], rewards));

  const isDirty = miniJourney ? isDirtyMiniJourney : isDirtyNoMiniJourney;

  return (
    <FormEdit
      backRoutePath={`../`}
      onRemove={onRemove}
      hasValidationErrors={Object.keys(errors).length !== 0}
      enableSave={isDirty}
      title={
        miniJourney
          ? miniJourney?.translations?.[primaryLocale.key]?.headerHeadline
          : "New Mini Journey"
      }
      editType={miniJourney?.id ? FormEditType.EDIT : FormEditType.ADD}
      id={miniJourney?.id}
      errors={errors as any}
      loading={false}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      recordIdentifier={headerHeadline}
      localeSupported
    >
      {locale => (
        <>
          <Row gutter={{ md: 20 }}>
            <Col md={24} lg={12}>
              <BJInputFormItem
                control={control}
                label={`Header headline (${locale.label})`}
                fieldName={`translations.${locale.key}.headerHeadline`}
                key={`translations.${locale.key}.headerHeadline`}
              />
              <Form.Item
                required
                label="Header Image"
                extra={""}
                validateStatus={errors.headerImage && "error"}
              >
                <DropAndCrop
                  initialUrl={miniJourney?.headerImage}
                  allowNaturalImageUpload={true}
                  title="Header Image"
                  setUploadUrl={handleHeaderImageUrl}
                  setBlurhash={handleHeaderBlurhash}
                  uploadImage={uploadMiniJourneyImage}
                  lockedRatio={AspectRatio.Free}
                  previewImageWidth={"10"}
                  defaultCropBoxWidth={300}
                  extra="Best resolution for this will be 1440x960"
                />
              </Form.Item>
              <BJMdFormItem
                disabled={false}
                control={control}
                error={!!errors?.translations?.[locale.key]?.introText}
                label={`Intro text (${locale.label})`}
                message={errors?.translations?.[locale.key]?.introText?.message}
                required={true}
                fieldName={`translations.${locale.key}.introText`}
                key={`translations.${locale.key}.introText`}
              />
              <Form.Item
                required
                label="Reward Image Banner"
                validateStatus={errors.rewardImageBanner && "error"}
                extra=""
              >
                <DropAndCrop
                  initialUrl={miniJourney?.rewardImageBanner}
                  allowNaturalImageUpload={true}
                  title="Reward Image Banner"
                  setUploadUrl={handleRewardImageBannerUrl}
                  setBlurhash={handleRewardBlurhash}
                  uploadImage={uploadMiniJourneyImage}
                  lockedRatio={AspectRatio.Free}
                  previewImageWidth={"10"}
                  defaultCropBoxWidth={300}
                  extra={"Best resolution for this will be 1200 x 400"}
                />
              </Form.Item>
              {tasks.map((task, index) => (
                <StyledRow
                  onClick={() => editTask(task)}
                  key={index}
                  justify="space-between"
                  align="middle"
                >
                  <ImagePresenter
                    disabled={true}
                    imageUrl={task.image}
                    width="70px"
                  />
                  <Typography.Text>{task.type}</Typography.Text>
                  <Typography.Text>{task.id}</Typography.Text>
                  <BJCopy textToCopy={task.id} />
                  <Typography.Text>{task.contentType}</Typography.Text>
                  <Typography.Text title={task?.url}>
                    {task?.url?.length > 23
                      ? `${task?.url?.slice(0, 20)}...`
                      : task?.url}
                  </Typography.Text>
                  <Divider />
                </StyledRow>
              ))}
              {tasks.length <= 6 && (
                <Button onClick={() => setIsAddTaskModalVisible(true)}>
                  Add Task
                </Button>
              )}
              {tasks.length < 1 && (
                <Row>
                  <Typography.Text type="danger">
                    Add at least 1 task
                  </Typography.Text>
                </Row>
              )}
            </Col>
            <Col md={24} lg={12}>
              <Form.Item label="Sponsor" name="sponsorId">
                <Controller
                  control={control}
                  name="sponsorId"
                  render={({ field: { onChange, value } }) => (
                    <Select onChange={onChange} value={value} size="large">
                      <Select.Option value="">-</Select.Option>
                      {sponsors.map(sponsor => (
                        <Select.Option value={sponsor.id} key={sponsor.id}>
                          {sponsor.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
              <BJInputFormItem
                control={control}
                label={`Sponsor text (${locale.label})`}
                required={false}
                fieldName={`translations.${locale.key}.sponsorText`}
                key={`translations.${locale.key}.sponsorText`}
              />
              <Form.Item label="Start | End" key="startEnd">
                <Controller
                  name="startEnd"
                  control={control}
                  defaultValue={[
                    moment(Date.now()),
                    moment(Date.now()).add(1, "week"),
                  ]}
                  render={({ field: { onChange, value } }) => (
                    <RangePicker
                      format={"YYYY-MM-DD HH:mm"}
                      defaultValue={[moment(Date.now()), moment(Date.now())]}
                      showTime
                      value={value}
                      onChange={onChange}
                      size={"large"}
                    />
                  )}
                />
              </Form.Item>
              <BJSwitchFormItem
                label={"Active"}
                fieldName={"isActive"}
                control={control}
              />
              <BJSwitchFormItem
                label={"Hide countdown"}
                fieldName={"hideCountdown"}
                control={control}
              />
              {storedSponsor || sponsor?.length > 0 ? (
                <BJSwitchFormItem
                  label={"Show Loyalty progress"}
                  fieldName={"isLoyaltyProgressShown"}
                  control={control}
                />
              ) : null}

              {storedSponsor || sponsor?.length > 0
                ? rewards.map((reward, index) => (
                    <StyledRow
                      onClick={() => editReward(reward)}
                      key={index}
                      justify="space-between"
                      align="middle"
                    >
                      <Typography.Text>{reward.reward}</Typography.Text>
                      <Typography.Text>
                        {reward?.translations?.[locale.key]?.title}
                      </Typography.Text>
                      <Typography.Text>{reward.type}</Typography.Text>
                      <Divider />
                    </StyledRow>
                  ))
                : null}
              {storedSponsor || sponsor?.length > 0 ? (
                <Button onClick={() => setIsAddRewardModalVisible(true)}>
                  Add Reward
                </Button>
              ) : (
                <>
                  <BJMdFormItem
                    label={`Popup Title (${locale.label})`}
                    fieldName={`translations.${locale.key}.popupTitle`}
                    key={`translations.${locale.key}.popupTitle`}
                    control={control}
                    disabled={false}
                  />
                  <BJMdFormItem
                    control={control}
                    label={`Popup Description (${locale.label})`}
                    fieldName={`translations.${locale.key}.popupDescription`}
                    key={`translations.${locale.key}.popupDescription`}
                    disabled={false}
                  />
                </>
              )}
            </Col>
          </Row>
          <TaskModal
            show={isAddTaskModalVisible}
            onHide={() => {
              setSelectedTask(null);
              setIsAddTaskModalVisible(false);
            }}
            onAdd={_task => {
              if (_task) {
                setTasks([...tasks, _task]);
              }
            }}
            onDelete={id => {
              setTasks(tasks.filter(a => a.id !== id));
            }}
            onUpdate={_task => {
              setTasks(prev =>
                prev.map(a => {
                  return a.id === _task.id ? _task : a;
                })
              );
            }}
            task={selectedTask}
          />
          <RewardModal
            show={isAddRewardModalVisible}
            onHide={() => {
              setSelectedReward(null);
              setIsAddRewardModalVisible(false);
            }}
            onAdd={_reward => {
              if (_reward) {
                setRewards([...rewards, _reward]);
              }
            }}
            onDelete={id => {
              setRewards(rewards.filter(a => a.id !== id));
            }}
            onUpdate={_reward => {
              setRewards(prev =>
                prev.map(a => {
                  return a.id === _reward.id ? _reward : a;
                })
              );
            }}
            reward={selectedReward}
          />
        </>
      )}
    </FormEdit>
  );
};

const StyledRow = styled(Row)`
  cursor: pointer;
`;

import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Row,
  Col,
  Typography,
  Layout,
  Switch,
  Card,
  Space,
  Divider,
  List,
} from "antd";
import { Form } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UserService } from "../../../services";
import BJButton, { ButtonTypes } from "../../../components/theme/atoms/Button";
import { commonErrors, commonSuccessMessages } from "../../../language";
import BJInput from "../../../components/theme/atoms/BJInput";
import { CenteredSpinner, ConfirmationModal } from "../../../components";
import BJTable, {
  BJColumnType,
} from "../../../components/theme/molecules/BJTable";
import { Sorter } from "../../../components/theme/util/sorter";
import { NotificationType } from "../../../components/theme/util/notificationObj";
import {
  formatDate,
  formatStringDate,
  generateServerError,
} from "../../../utils";
import { BJNotification } from "../../../components/theme/atoms/BJNotification";
import { logException } from "../../../utils/exceptionLogger";

const { requiredError, deleteRecordError, searchRecordError } = commonErrors;
const { deleteSuccessMessage, deleteSuccessMessageHeader } =
  commonSuccessMessages;

const schema = yup.object().shape({
  searchText: yup.string().required(requiredError),
});

type FormValues = {
  searchText: string;
  reason?: string;
};

type DisplayPregnancy = {
  dueDate?: string;
  name?: string;
  isActive?: boolean;
  firstDayInLastPeriod?: string;
  isCompleted?: boolean;
  isTemporary?: boolean;
  isTesting?: boolean;
  lastBirthNotificationDate?: firebase.default.firestore.Timestamp;
  timestamp?: firebase.default.firestore.Timestamp;
  daysInMenstrualCycle: number;
  partner: string;
};

type DisplayUser = {
  id?: string;
  name?: string;
  email?: string;
  city?: string;
  creationTime: any;
  dateOfBirth: string;
  partnerName: string;
  partnerId: string;
};

type DisplayChildren = {
  dateOfBirth: string;
  name: string;
  gender: string;
};

type Partner = {
  userId: string;
};

enum Gender {
  Unspecified,
  Male,
  Female,
}
const UserSearchPage = () => {
  const [userResponse, setUserResponse] = useState<{
    user: DisplayUser | null;
    pregnancy?: DisplayPregnancy;
    children?: DisplayChildren[];
    partners?: Partner[];
  }>({ user: null });

  const [isSearching, setIsSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const onSearch = async (data: FormValues) => {
    setIsSearching(true);
    setHasSearched(true);
    try {
      const response = await UserService.getUserByUserIdOrEmail(
        data.searchText
      );
      if (response) {
        const formattedResponse = mapUserResponse(response);
        setUserResponse(formattedResponse);
      }
    } catch (error) {
      logException(error);
      const serverErrorMessage = generateServerError(error);
      setUserResponse({ user: null });
      BJNotification({
        type: NotificationType.Error,
        message: searchRecordError,
        description: serverErrorMessage,
      });
    } finally {
      setIsSearching(false);
    }
  };

  const onDelete = async () => {
    const reasonForDelete = getValues("reason") ?? "";
    const id = userResponse?.user?.id;
    setIsDeleting(true);
    setShowDeleteModal(false);
    try {
      await UserService.deleteUser(id!, "admin deletes user", reasonForDelete);
      BJNotification({
        type: NotificationType.Success,
        message: deleteSuccessMessageHeader,
        description: deleteSuccessMessage,
      });
      const mappedResponse = mapUserResponse({ user: null });
      reset({ reason: "", searchText: "" });
      setUserResponse(mappedResponse);
      setIsDeleting(false);
    } catch (error) {
      const serverErrorMessage = generateServerError(error);
      setUserResponse({ user: null });
      BJNotification({
        type: NotificationType.Error,
        message: deleteRecordError,
        description: serverErrorMessage,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const mapUserResponse = (responseData: any) => {
    let formattedPregnancy: DisplayPregnancy | null = null;
    const user: DisplayUser =
      !responseData.user && !responseData.authUser
        ? null
        : {
            ...responseData.user,
            ...responseData.authUser,
            creationTime: responseData.authUser?.metadata?.creationTime,
            dateOfBirth: formatDate(responseData.user?.dateOfBirth),
          };
    const activePregnancy: DisplayPregnancy = responseData?.pregnancies?.find(
      (pregnancy: any) => pregnancy.isActive
    );

    if (activePregnancy) {
      formattedPregnancy = {
        ...activePregnancy,
        name: user?.name,
        partner: user.partnerName ? "Yes" : "No",
      };
    }

    const children: DisplayChildren[] =
      responseData?.children?.map((child: any) => ({
        ...child,
        gender: child.gender === Gender.Male ? "Male" : "Female",
        dateOfBirth: child.dateOfBirth,
      })) ?? [];

    const partnersList: Partner[] = responseData.partners;
    const formattedResponse = {
      ...responseData,
      user: user,
      pregnancy: formattedPregnancy,
      children: children!,
      partnersList,
    };
    return formattedResponse;
  };

  const { user, pregnancy, children, partners } = userResponse;

  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        text={commonErrors.onRemoveContentError}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={onDelete}
        additionalContent={() => (
          <Form.Item label="Reason">
            <Controller
              control={control}
              name="reason"
              render={({ field }) => <BJInput autoFocus {...field} />}
            />
          </Form.Item>
        )}
      />
      <Layout>
        <Form name="Basic" layout="vertical" onFinish={handleSubmit(onSearch)}>
          <Typography.Title level={2}>
            {"Change user information"}
          </Typography.Title>
          <Row gutter={[12, 20]}>
            <Col span={10} offset={4}>
              <Form.Item
                label=""
                name="searchText"
                validateStatus={errors.searchText && "error"}
                extra={
                  <Typography.Paragraph type="danger">
                    {errors.searchText?.message}
                  </Typography.Paragraph>
                }
                required
              >
                <Controller
                  control={control}
                  name="searchText"
                  render={({ field }) => (
                    <BJInput
                      placeholder="Enter user id here"
                      {...field}
                      autoFocus
                    />
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Space>
                <BJButton
                  buttonType={ButtonTypes.Save}
                  disabled={isSearching}
                  loading={isSearching}
                  size="large"
                  htmlType="submit"
                >
                  Search
                </BJButton>
                <BJButton
                  buttonType={ButtonTypes.Delete}
                  disabled={isSearching || !user}
                  loading={isDeleting}
                  size="large"
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  Delete
                </BJButton>
              </Space>
            </Col>
          </Row>
        </Form>
        {isSearching ? (
          <CenteredSpinner />
        ) : user ? (
          <>
            <Divider></Divider>
            <UserInfomation user={user} />
            {pregnancy && <PregnancyInformation pregnancy={pregnancy} />}
            {children!.length > 0 && (
              <ChildInformation>{children}</ChildInformation>
            )}
            <Card title="Has shared account with" bordered={false}>
              <Switch
                checked={(partners && partners.length > 0) || !!user.partnerId}
              />
              {partners && partners.length > 0 ? (
                <List
                  size="large"
                  header={<Typography.Text>Partners List </Typography.Text>}
                  dataSource={partners}
                  renderItem={item => (
                    <List.Item>
                      <Typography.Text>{item.userId} </Typography.Text>
                    </List.Item>
                  )}
                />
              ) : (
                user.partnerId && (
                  <List
                    size="large"
                    header={<Typography.Text>Partner</Typography.Text>}
                    dataSource={[user.partnerId]}
                    renderItem={item => (
                      <List.Item>
                        <Typography.Text>{item}</Typography.Text>
                      </List.Item>
                    )}
                  />
                )
              )}
            </Card>
          </>
        ) : (
          hasSearched && (
            <Row>
              <Divider />
              <Col offset={5}>
                <Typography.Title type="danger">
                  No records available
                </Typography.Title>
              </Col>
            </Row>
          )
        )}
      </Layout>
    </>
  );
};

const FormattedTitle = ({ title }: { title: string }) => {
  return <Typography.Text strong>{title}:</Typography.Text>;
};

const UserInfomation = ({ user }: { user: DisplayUser }) => {
  return (
    <Card title="User information" bordered={false}>
      <Row>
        <Col span={8}>
          <Row gutter={4}>
            <Col>
              <FormattedTitle title="Name" />
            </Col>
            <Col>{user?.name}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={4}>
            <Col>
              <FormattedTitle title="Email" />
            </Col>
            <Col>{user?.email}</Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Row gutter={4}>
            <Col>
              <FormattedTitle title="City" />
            </Col>
            <Col>{user?.city}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={4}>
            <Col>
              <FormattedTitle title="Created" />
            </Col>
            <Col>{formatStringDate(user?.creationTime)}</Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Row gutter={4}>
            <Col>
              <FormattedTitle title="Date of Birth" />
            </Col>
            <Col>{user?.dateOfBirth}</Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

const PregnancyInformation = ({
  pregnancy,
}: {
  pregnancy: DisplayPregnancy;
}) => {
  return (
    <Card title="Pregnancy information" bordered={false}>
      <Row>
        <Col span={8}>
          <Row gutter={4}>
            <Col>
              <FormattedTitle title="Name" />
            </Col>
            <Col>{pregnancy?.name}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={4}>
            <Col>
              <FormattedTitle title="Last birth notification date" />
            </Col>
            <Col>{formatDate(pregnancy?.lastBirthNotificationDate)}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={4}>
            <Col>
              <FormattedTitle title="Due date" />
            </Col>
            <Col>{formatDate(pregnancy?.dueDate)}</Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Row gutter={4}>
            <Col>
              <FormattedTitle title=" Days in menstrual cycle" />
            </Col>
            <Col>{pregnancy?.daysInMenstrualCycle}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={4}>
            <Col>
              <FormattedTitle title="First day in last period" />
            </Col>
            <Col>{formatDate(pregnancy?.firstDayInLastPeriod)}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={4}>
            <Col>
              <FormattedTitle title="Partner" />
            </Col>
            <Col>{pregnancy?.partner}</Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

const ChildInformation = ({
  children,
}: {
  children: DisplayChildren[] | undefined;
}) => {
  const columns: BJColumnType<DisplayChildren>[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 2,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Date of birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      width: 2,
      ellipsis: true,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: 1,
      ellipsis: true,
    },
  ];

  return (
    <Card title="Children" bordered={false}>
      <BJTable
        hideRecordCount
        pagination={false}
        columns={columns}
        dataSource={children!}
      />
    </Card>
  );
};

export default UserSearchPage;
